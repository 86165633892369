import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import TimeComponent from '@/components/TimeComponent/index';
import { CustomerReceiveList } from '@/api/ceshi_xiugai/product';
import { getUserList as _getUserList } from '@/api/updateUserInfo/structure';
import { getStructureListArr } from '@/api/PublicAPI';
import { getCustomerLevel, agentFlowerNameList } from '@/api/ceshi_xiugai/whole';
import Head from '@/components/head/index';
import DatePicker from '@/components/DatePicker/index';
export default {
  name: 'ClassManagement',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      menuId: localStorage.getItem('menuId'),
      // 系统教程
      title: '接收客户信息',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      ClassSearch: '',
      listLoading: true,
      tableData: [],
      Creator_Arr: [],
      Receiver_Arr: [],
      flower_Array: [],
      bumenoptions: [],
      GetFieldInfodata2: [],
      tempId: '',
      bumenoptionsId: '',
      filtering: {
        attributionshow: false,
        ReceivertShow: false,
        finalFollowshow: false,
        CreatorShow: false,
        NextVisitShow: false,
        bumenoptionsShow: false
      },
      screen: {
        start: [],
        search: '',
        create_stime: '',
        create_etime: '',
        source_client_id: '',
        Batchstudentlevel: '',
        next_time_order: '',
        create_time_order: '',
        follow_time_order: '',
        // 表头筛选
        attribution: '',
        Receiver: '',
        finalFollow: '',
        finalFollows: '',
        finalFollowe: '',
        Creator: '',
        NextVisit: '',
        NextVisits: '',
        NextVisite: '',
        bumenoptionsName: '',
        bumenoptionsId: ''
      }
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.screening();
    } else {}
  },
  components: {
    Head: Head,
    DatePicker: DatePicker,
    TimeComponent: TimeComponent
  },
  methods: {
    FlowerFocus: function FlowerFocus() {
      var _this2 = this;
      agentFlowerNameList().then(function (res) {
        _this2.flower_Array = res.data;
      });
    },
    PostMultipleFocus: function PostMultipleFocus() {
      var _this3 = this;
      getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        _this3.GetFieldInfodata2 = res.data;
      });
    },
    FinalPicker: function FinalPicker(val) {
      this.screen.create_stime = val[0];
      this.screen.create_etime = val[1];
    },
    getUserList: function getUserList() {
      var _this4 = this;
      _getUserList({
        get_type: '0',
        type: '0'
      }).then(function (res) {
        //跟进人
        _this4.Receiver_Arr = res.data;
      });
    },
    getUserLists: function getUserLists() {
      var _this5 = this;
      _getUserList({
        get_type: '0',
        type: '0'
      }).then(function (res) {
        //创建人
        _this5.Creator_Arr = res.data;
      });
    },
    //时间
    screening: function screening() {
      var startTime = moment().startOf('month');
      var start = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
      var end = new Date();
      this.screen.start.unshift(start);
      this.screen.start.push(end);
      start = Date.parse(start).toString().substring(0, 10);
      end = Date.parse(end).toString().substring(0, 10);
      this.screen.create_stime = start;
      this.screen.create_etime = end;
      this.getList(this.query.page, this.query.pagesize);
    },
    getList: function getList(page, pagesize) {
      var _this6 = this;
      this.listLoading = true;
      var then = this.screen;
      CustomerReceiveList({
        page: page,
        pagesize: pagesize,
        create_stime: then.create_stime,
        create_etime: then.create_etime,
        source_client_id: then.source_client_id,
        customer_level: then.Batchstudentlevel.toString(),
        wxnum_mobile_cname: then.search,
        next_time_order: then.next_time_order,
        create_time_order: then.create_time_order,
        follow_time_order: then.follow_time_order,
        mobile_address: then.attribution,
        follow_user_id: then.Receiver.toString(),
        start_follow_time: then.finalFollows,
        end_follow_time: then.finalFollowe,
        create_user_id: then.Creator,
        next_follow_stime: then.NextVisits,
        next_follow_etime: then.NextVisite,
        follow_structuress: then.bumenoptionsId.toString()
      }).then(function (respomse) {
        _this6.tableData = respomse.data.data;
        _this6.query.total = respomse.data.total;
        _this6.listLoading = false;
      }).catch(function () {
        _this6.tableData = [];
        _this6.listLoading = false;
      });
    },
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'create_time') {
        if (column.order == 'ascending') {
          this.screen.create_time_order = '1';
        } else if (column.order == 'descending') {
          this.screen.create_time_order = '2';
        } else {
          this.screen.create_time_order = '0';
        }
        this.screen.follow_time_order = '';
        this.screen.next_time_order = '';
      } else if (column.prop == 'follw_time') {
        if (column.order == 'ascending') {
          this.screen.follow_time_order = '1';
        } else if (column.order == 'descending') {
          this.screen.follow_time_order = '2';
        } else {
          this.screen.follow_time_order = '0';
        }
        this.screen.create_time_order = '';
        this.screen.next_time_order = '';
      } else if (column.prop == 'next_time') {
        if (column.order == 'ascending') {
          this.screen.next_time_order = '1';
        } else if (column.order == 'descending') {
          this.screen.next_time_order = '2';
        } else {
          this.screen.next_time_order = '0';
        }
        this.screen.follow_time_order = '';
        this.screen.create_time_order = '';
      }
      this.getList(this.query.page, this.query.pagesize);
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      var _this = this.filtering;
      if (item === '归属地') {
        _this.attributionshow = true;
      } else if (item === '跟进人') {
        _this.ReceivertShow = true;
      } else if (item === '最后跟进日期') {
        _this.finalFollowshow = true;
      } else if (item === '创建人') {
        _this.CreatorShow = true;
      } else if (item === '下次回访日期') {
        _this.NextVisitShow = true;
      } else if (item === '跟进人所属部门') {
        _this.bumenoptionsShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      var _this = this.filtering;
      var screen = this.screen;
      if (item === '归属地') {
        _this.attributionshow = false;
        screen.attribution = '';
      } else if (item === '跟进人') {
        _this.ReceivertShow = false;
        screen.Receiver = '';
      } else if (item === '创建人') {
        _this.CreatorShow = false;
        screen.Creator = '';
      } else if (item === '跟进人所属部门') {
        _this.bumenoptionsShow = false;
        screen.bumenoptionsName = '';
        screen.bumenoptionsId = '';
      }
      this.getList(this.query.page, this.query.pagesize);
    },
    FollowClick: function FollowClick(start, end, title) {
      var _this = this.screen;
      this.query.page = 1;
      if (title === '最后跟进日期') {
        _this.finalFollows = start;
        _this.finalFollowe = end;
      } else if (title === '下次回访日期') {
        _this.NextVisits = start;
        _this.NextVisite = end;
      }
      this.getList(this.query.page, this.query.pagesize);
    },
    forkClick: function forkClick(item) {
      this.query.page = 1;
      var screen = this.screen;
      var filtering = this.filtering;
      if (item === '最后跟进日期') {
        filtering.finalFollowshow = false;
        screen.finalFollows = '';
        screen.finalFollowe = '';
      } else if (item === '下次回访日期') {
        filtering.NextVisitShow = false;
        screen.NextVisits = '';
        screen.NextVisite = '';
      }
      this.getList(this.query.page, this.query.pagesize);
    },
    handleChange: function handleChange(val) {
      //跟进人所属部门
      var share = [val].map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      this.screen.bumenoptionsId = share;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    DumenFocus: function DumenFocus() {
      var _this7 = this;
      getStructureListArr({
        type: '0',
        get_type: '0'
      }).then(function (respomse) {
        _this7.bumenoptions = respomse.data;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //获取选中id
      for (var i = 0; i < val.length; i++) {
        this.tempId = val[i].id;
      }
      if (val.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(val.pop());
      }
    },
    attributionClick: function attributionClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    ReceiverInput: function ReceiverInput(val) {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    ReceiverInputs: function ReceiverInputs(val) {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    ReceiverMethod: function ReceiverMethod(val) {
      var _this8 = this;
      if (val == '') {
        _getUserList({
          get_type: '0',
          type: '0'
        }).then(function (res) {
          _this8.Receiver_Arr = res.data;
        });
      } else {
        _getUserList({
          get_type: '0',
          type: '0',
          real_name: val
        }).then(function (res) {
          _this8.Receiver_Arr = res.data;
        });
      }
    },
    ReceiverMethods: function ReceiverMethods(val) {
      var _this9 = this;
      if (val == '') {
        _getUserList({
          get_type: '0',
          type: '0'
        }).then(function (res) {
          _this9.Creator_Arr = res.data;
        });
      } else {
        _getUserList({
          get_type: '0',
          type: '0',
          real_name: val
        }).then(function (res) {
          _this9.Creator_Arr = res.data;
        });
      }
    },
    goTodetails: function goTodetails(row, val) {
      //客户详情

      var routeUrl = this.$router.resolve({
        path: '/receiveDetail?Cid=' + row.cid + '&CData_id=' + row.cd_id
      });
      var asas = JSON.stringify(this.resultES);
      localStorage.setItem('resultES', asas);
      window.open(routeUrl.href, '_blank');
    },
    queryClick: function queryClick() {
      this.getList(this.query.page, this.query.pagesize);
    }
  }
};